<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            #
          </th>
          <th class="text-left">
            Address
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, i) in garageList"
          :key="i"
        >
          <td>{{ i + 1 }}</td>
          <td>{{ item.name }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
export default {
  
  props: ['garageList']

}
</script>

<style>

</style>