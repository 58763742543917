<template>
  <v-card color="" elevation="0" outlined class="pa-0">
    <div class="card-header">
      <v-card-title class="d-flex justify-space-between pa-5">
        <div class="white--text headline">Ajouter Parameters</div>
        <v-icon color="white" class="d-none d-sm-flex" @click="toggle()"
          >mdi-close-thick</v-icon
        >
      </v-card-title>
    </div>
    <v-divider />
    <v-card-text style="height: auto">
      <v-sheet
        v-if="loading"
        class="d-flex align-center justify-center"
        style="height: 50vh"
      >
        <v-progress-circular
          indeterminate
          size="50"
          color="primary"
        ></v-progress-circular>
      </v-sheet>
      <v-card elevation="0" v-else>
        <v-card-text class="pt-5 pb-0">
          <v-row no-gutters v-for="item in formText" :key="item.id">
            <v-col cols="12" sm="5">
              <v-subheader class="pa-0 subtitle-1 font-weight-bold">{{
                item.title
              }}</v-subheader>
            </v-col>
            <v-col cols="12" sm="7">
              <v-text-field outlined dense v-model="item.value" v-mask="['####']"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-row no-gutters justify-sm="space-between">
        <v-col cols="12" sm="auto" order="2" order-sm="1">
          <v-btn
            :block="$vuetify.breakpoint.xs ? true : false"
            color="grey"
            class="mt-5 mt-sm-0"
            elevation="0"
            dark
            @click="toggle()"
            >Cancel
          </v-btn>
        </v-col>
        <v-col cols="12" sm="auto" order="1" order-sm="1">
          <v-btn
            :block="$vuetify.breakpoint.xs ? true : false"
            color="primary"
            elevation="0"
            dark
            :loading="loader"
            @click="updateParameters"
          >
            Confirm
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { mask } from "vue-the-mask";

export default {
  directives: { mask },

  props: {
    toggle: {
      type: Function,
      required: false,
    },
  },

  data: () => ({
    loading: true,
    loader: false,
    formText: [
      {
        id: "pricePerKm",
        title: "Price per KM",
        value: "",
      },
      {
        id: "basicFlatRate",
        title: "Basic Flat Rate",
        value: "",
      },
      // {
      //   id: "systemDate",
      //   title: "System Date",
      //   value: "",
      // },
      {
        id: "seatPercent",
        title: "Seat Percentage",
        value: "",
      },
      {
        id: "luggagePercent",
        title: "Luggage Percentage",
        value: "",
      }
    ],
  }),

  computed: {
    ...mapState({
      parameters: 'parameters'
    })
  },

  methods: {
    async updateParameters() {
      this.loader = true
      const payload = {
        pricePerKm: this.formText[0].value,
        basicFlatRate: this.formText[1].value,
        // systemDate: this.formText[2].value,
        seatPercent: this.formText[3].value,
        luggagePercent: this.formText[4].value
      }
      await this.$store.dispatch('updateParameters', { id: this.parameters.id, parameters: payload})
      this.loader = false
      this.toggle()
    }
  },

  async created() {

    this.formText.forEach(val => {
      if(val.id == 'pricePerKm') {
        val.value = this.parameters.pricePerKm
      } else if(val.id == 'basicFlatRate') {
        val.value = this.parameters.basicFlatRate
      } else if(val.id == 'systemDate') {
        val.value = this.parameters.systemDate
      } else if(val.id == 'seatPercent') {
        val.value = this.parameters.seatPercent
      } else if(val.id == 'luggagePercent') {
        val.value = this.parameters.luggagePercent
      }
    })

    this.loading = false
  }
};
</script>

<style></style>
