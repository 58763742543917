var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pa-5",attrs:{"elevation":"1"}},[_c('h1',{staticClass:"display-1"},[_vm._v("Drivers")]),_c('v-divider',{staticClass:"mt-3"}),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-select',{attrs:{"items":_vm.items,"label":"Status","outlined":"","hide-details":"","dense":""},model:{value:(_vm.driverStatus),callback:function ($$v) {_vm.driverStatus=$$v},expression:"driverStatus"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","outlined":"","dense":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-btn',{attrs:{"color":"primary","light":"","block":""},on:{"click":function($event){return _vm.toggleNewDriver()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Ajouter Driver ")],1)],1)],1),_c('div',{staticClass:"mt-5"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.drivers,"search":_vm.search,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-start align-center py-3"},[_c('v-avatar',{staticClass:"mr-3"},[(item.imageURL != '')?_c('img',{attrs:{"src":"imageURL","alt":""}}):_c('img',{attrs:{"src":require("@/assets/avatar-male.png"),"alt":"John"}})]),_c('span',[_vm._v(" "+_vm._s(item.fullName)+" ")])],1)]}},{key:"item.upcomingTrip",fn:function(ref){
var item = ref.item;
return [(!_vm.checkObject(item.upcomingTrip))?_c('div',{staticClass:"d-flex flex-column align-center justify-center py-3 my-3",staticStyle:{"background-color":"#D2EBD3"}},[_c('p',{staticClass:"my-0"},[_vm._v(_vm._s(_vm.getDate(item.upcomingTrip.date)))]),_c('p',{staticClass:"my-0"},[_vm._v(_vm._s(_vm.getTime(item.upcomingTrip.time)))]),_c('p',{staticClass:"my-0"},[_vm._v(_vm._s(item.upcomingTrip.customerName))]),_c('p',{staticClass:"my-0"},[_vm._v(_vm._s(item.upcomingTrip.address))])]):_c('div',[_vm._v(" - ")])]}},{key:"item.assignedVans",fn:function(ref){
var item = ref.item;
return [(item.assignedVans.length > 0)?_c('div',{staticClass:"d-flex flex-column align-center justify-center"},_vm._l((item.assignedVans),function(van){return _c('p',{key:van,staticClass:"my-0"},[_vm._v(_vm._s(van))])}),0):_c('div',[_vm._v(" - ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row align-center justify-center"},[_c('v-btn',{attrs:{"text":"","icon":"","color":"grey"},on:{"click":function($event){return _vm.goToDriver(item.id)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)],1)]}}],null,true)})],1),_c('v-dialog',{attrs:{"persistent":"","scrollable":""},model:{value:(_vm.newDriverDialog),callback:function ($$v) {_vm.newDriverDialog=$$v},expression:"newDriverDialog"}},[_c('driver-form',{attrs:{"toggle":_vm.toggleNewDriver,"getDrivers":_vm.getDrivers}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }