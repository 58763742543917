<template>
  <div>
    <v-sheet
      v-if="loading"
      class="d-flex align-center justify-center"
      style="height: 50vh"
    >
      <v-progress-circular
        indeterminate
        size="50"
        color="primary"
      ></v-progress-circular>
    </v-sheet>
    <div v-else>
      <!-- header -->
      <v-sheet class="pa-5" elevation="1">
        <div>
          <h1 class="display-1">Parameters</h1>
          <v-btn color="grey" large text absolute top right @click="toggleNewParameter">
            <v-icon large>mdi-pencil</v-icon>
          </v-btn>
        </div>
      </v-sheet>

      <!-- Rate -->
      <v-row class="mt-5">
        <v-col cols="12" md="6">
          <v-card class="d-flex justify-center align-end pb-5 pr-5" height="150">
            <div class="display-4 text-number">{{
              parameters.pricePerKm
            }} 
            </div>
            <v-icon x-large class="mb-2">mdi-currency-eur</v-icon>
            <div class="display-1 pb-2 primary--text ">{{ 'Price/KM' }}</div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="d-flex justify-center align-end pb-5 pr-5" height="150">
            <div class="display-4 text-number">{{
              parameters.basicFlatRate
            }} 
            </div>
            <v-icon x-large class="mb-2">mdi-currency-eur</v-icon>
            <div class="display-1 pb-2 primary--text ">{{ 'Basic Flat Rate' }}</div>
          </v-card>
        </v-col>
        <!-- <v-col cols="4">
          <v-card class="d-flex justify-center align-end pb-5 pr-5" height="150">
            <div class="display-4 text-number">{{
              parameters.systemDate
            }} 
            </div>
            <v-icon x-large class="mb-2">mdi-currency-eur</v-icon>
            <div class="display-1 pb-2 primary--text ">{{ 'System Date' }}</div>
          </v-card>
        </v-col> -->
        <v-col cols="12" md="6">
          <v-card class="d-flex justify-center align-end pb-5 pr-5" height="150">
            <div class="display-4 text-number">{{
              `${parameters.seatPercent}%`
            }} 
            </div>
            <div class="display-1 pb-2 primary--text ">{{ 'Seat Percentage' }}</div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="d-flex justify-center align-end pb-5 pr-5" height="150">
            <div class="display-4 text-number">{{
              `${parameters.luggagePercent}%`
            }} 
            </div>
            <div class="display-1 pb-2 primary--text ">{{ 'Luggage Percentage' }}</div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <div class="d-flex flex-row justify-space-between align-center pr-5">
              <v-card-title>Garage List</v-card-title>
              <v-btn color="success" fab small @click="addDialog = !addDialog"><v-icon>mdi-plus</v-icon></v-btn>
            </div>

            <v-dialog v-model="addDialog" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Add New Garage Address</v-card-title>
                <v-card-text>
                  <address-picker :getAddress="getAddress" />
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="addNewAddress()">Add to garage address</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-divider></v-divider>
            <v-card-text>
              <garage-table :garageList="garageList" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      
      <v-dialog v-model="newParameterDialog" persistent scrollable width="720">
      <parameter-form :toggle="toggleNewParameter" />
    </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as fb from '../../firebase'
import ParameterForm from '../../components/parameters/ParameterForm.vue';
import GarageTable from '../../components/parameters/GarageTable.vue';
import AddressPicker from '../../components/driver/AddressPicker.vue';

export default {
  components: { ParameterForm, GarageTable, AddressPicker },

  data: () => ({
    loading: true,
    newParameterDialog: false,
    addDialog: false,
    garageAddress: null,
    garageList: []
  }),

  async created() {
    
    await this.$store.dispatch('getParameters')
    let addressList = await this.$store.dispatch('getGarageAddressList')
    this.garageList = addressList ? addressList : []
    this.loading = false
  },

  computed: {
    ...mapState({
      parameters: 'parameters'
    })
  },

  methods: {

    getAddress(payload) {
      this.garageAddress = payload
      console.log(this.garageAddress)
    },

    toggleNewParameter() {
      this.newParameterDialog = !this.newParameterDialog
    },

    async addNewAddress(){
      console.log(this.garageAddress)
      this.garageList.push(this.garageAddress)
      await fb.db.collection('garageAddressList').add(this.garageAddress)
      this.garageAddress = ''
      this.addDialog = !this.addDialog
    },
  }

};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Merriweather&display=swap");

.text-number {
  font-family: "Merriweather" !important;
}
</style>
