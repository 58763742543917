<template>
  <v-sheet class="pa-5" elevation="1">
    <h1 class="display-1">Drivers</h1>
    <v-divider class="mt-3"></v-divider>
    <v-row class="mt-5">
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-select
          v-model="driverStatus"
          :items="items"
          label="Status"
          outlined
          hide-details
          dense
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="6">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          outlined
          dense
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-btn color="primary" light block @click="toggleNewDriver()">
          <v-icon left>
            mdi-plus-circle
          </v-icon>
          Ajouter Driver
        </v-btn>
      </v-col>
    </v-row>
    <div class="mt-5">
      <v-data-table
        :headers="headers"
        :items="drivers"
        :search="search"
        hide-default-footer
        class="elevation-0"
      >
        <template v-slot:[`item.fullName`]="{ item }">
          <div class="d-flex flex-row justify-start align-center py-3">
            <v-avatar class="mr-3">
              <img v-if="item.imageURL != ''" src="imageURL" alt="" />
              <img v-else src="~@/assets/avatar-male.png" alt="John" />
            </v-avatar>
            <span>
              {{ item.fullName }}
            </span>
          </div>
        </template>
        <template v-slot:[`item.upcomingTrip`]="{ item }">
          <div class="d-flex flex-column align-center justify-center py-3 my-3" style="background-color: #D2EBD3" v-if="!checkObject(item.upcomingTrip)">
            <p class="my-0">{{ getDate(item.upcomingTrip.date) }}</p>
            <p class="my-0">{{ getTime(item.upcomingTrip.time) }}</p>
            <p class="my-0">{{ item.upcomingTrip.customerName }}</p>
            <p class="my-0">{{ item.upcomingTrip.address }}</p>
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template v-slot:[`item.assignedVans`]="{ item }">
          <div class="d-flex flex-column align-center justify-center" v-if="item.assignedVans.length > 0">
            <p v-for="van in item.assignedVans" :key="van" class="my-0">{{ van }}</p>
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex flex-row align-center justify-center">
            <v-btn text icon color="grey" @click="goToDriver(item.id)">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="newDriverDialog" persistent scrollable>
      <driver-form :toggle="toggleNewDriver" :getDrivers="getDrivers" />
    </v-dialog>
  </v-sheet>
</template>

<script>

export default {

  components: {
    DriverForm: () => import('@/components/driver/DriverForm.vue')
  },

  data: () => ({
    newDriverDialog: false,
    items: ["All", "Active", "Disable"],
    driverStatus: "All",
    search: "",
    headers: [
      {
        text: "Full Name",
        align: "center",
        value: "fullName",
      },
      { text: "Driver ID", align: "center", value: "customId" },
      { text: "Addresse", align: "center", value: "address" },
      { text: "Next Trip", align: "center", value: "upcomingTrip" },
      { text: "Assigned Vans", align: "center", value: "assignedVans" },
      { text: "Actions", align: "center", value: "actions" },
    ],
    drivers: []
  }),

  watch: {
    async driverStatus(val) {
      if(val == 'All') {
        this.drivers = await this.$store.dispatch('driver/getDrivers')
      } else {
        let drivers = await this.$store.dispatch('driver/getDriversByStatus', { status: val })
        if(drivers.length > 0) {
          this.drivers = drivers
        } else {
          this.drivers = []
        }
      }
    }
  },

  async created() {
    this.getDrivers()
  },


  methods: {

    async getDrivers() {
      this.drivers = await this.$store.dispatch('driver/getDrivers')
      console.log(this.drivers)
    },

    toggleNewDriver() {
      this.newDriverDialog = !this.newDriverDialog
    },

    goToDriver(id) {
      this.$router.push({
        name: 'Manager-Drivers-Single',
        params: { id: id }
      })
    },

    checkObject(obj) {
      return Object.keys(obj).length === 0;
    }
  }
}
</script>

<style>

</style>