<template>
  <v-card flat>
    <div
      style="height: 100vh"
      class="d-flex justify-center align-center"
      v-if="mapLoading"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-card-text v-else>
      <Autocomplete :returnLatLng="returnLatLng" autocompleteType="Source" />
      <div class="map-container">
        <div id="map" style="height: 22em"></div>
      </div>
      <div class="picker-container mt-10">
        <v-text-field
          outlined
          dense
          label="Address"
          v-model="address"
        ></v-text-field>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn color="success" class="px-5" @click="confirmAddress()">Confirm Address</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import loading from "../../mapsLoader";
import Autocomplete from "../shared/Autocomplete.vue";

export default {

  props: {
    getAddress: {
      type: Function,
      required: true
    }
  },

  components: { Autocomplete },

  data() {
    return {
      map: null,
      address: "",
      addressObject: {
        name: '',
        lat: 0,
        lng: 0
      },
      mapLoading: true,
      config: {
        center: { lat: 14.7251902, lng: -17.4596118 },
        zoom: 13,
        disableDefaultUI: true,
      },
      sourceMarker: null,
      sourceMarkers: [],
      markers: [
        {
          name: "",
          lat: 0,
          lng: 0,
        },
      ],
    };
  },

  methods: {
    initMap() {
      if (google && google.maps) {
        const mapContainer = document.getElementById("map");
        this.map = new google.maps.Map(mapContainer, this.config);
      }
    },

    async confirmAddress() {
      this.addressObject = this.markers[0]
      this.addressObject.name = this.address
      this.getAddress(this.addressObject)
    },

    returnLatLng(type, payload) {
      if (type == "Source") {
        this.markers[0] = payload;
        this.returnSourceMarker(payload);
      } else {
        this.markers[1] = payload;
        this.returnDestMarker(payload);
      }
    },

    returnSourceMarker(marker) {
      if (this.sourceMarkers.length > 0) {
        this.sourceMarkers.splice(0, 1);
        this.sourceMarker.setMap(null);
      }

      setTimeout(() => {
        this.sourceMarker = new google.maps.Marker({
          position: { lat: marker.lat, lng: marker.lng },
          map: this.map,
          draggable: true,
        });

        this.sourceMarkers.push(this.sourceMarker);

        new google.maps.event.addListener(
          this.sourceMarker,
          "dragend",
          (evt) => {
            let lat = evt.latLng.lat().toFixed(6);
            let lng = evt.latLng.lng().toFixed(6);

            this.markers[0].lat = lat;
            this.markers[0].lng = lng;
          }
        );
      }, 1000);
    },
  },

  created() {
    loading.then((res) => {
      if (res) {
        this.mapLoading = false;
        setTimeout(() => {
          this.initMap();
        }, 1000);
      }
    });
  },
};
</script>

<style></style>
